import {list as getAddressesInfo} from '@wix/ambassador-addresses-api-v1-address/http';
import {ambassadorWithHeaders} from '../utils/ambassador.utils';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {MemberAddressesInfoModel} from '../models/MemberAddressesInfo.model';
import {ADDRESSES_APP_DEFINITION_ID} from '@wix/wixstores-client-core';

export class MemberService {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  public readonly currency?: string;
  public addressesInfo: MemberAddressesInfoModel = {};
  public isAddressesAppInstalled!: boolean;
  public currentUserEmail: string = '';

  constructor({siteStore, flowAPI, currency}: {flowAPI: ControllerFlowAPI; siteStore: SiteStore; currency?: string}) {
    this.siteStore = siteStore;
    this.flowAPI = flowAPI;
    this.currency = currency;
  }

  public isMember(): boolean {
    return Boolean(this.flowAPI.controllerConfig.wixCodeApi.user.currentUser.loggedIn);
  }

  public async fetchAddressesInfo(): Promise<void> {
    const {data} = await ambassadorWithHeaders(getAddressesInfo({}), this.siteStore, this.flowAPI, this.currency);
    this.addressesInfo = new MemberAddressesInfoModel(data);
  }

  public async setIsAddressesAppInstalled(): Promise<void> {
    this.isAddressesAppInstalled = await this.flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
      sectionId: 'my_addresses',
      appDefinitionId: ADDRESSES_APP_DEFINITION_ID,
    });
  }

  public async getCurrentUserEmail(): Promise<void> {
    this.currentUserEmail = await this.flowAPI.controllerConfig.wixCodeApi.user.currentUser.getEmail();
  }
}

import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {createExpressCheckoutButtonsController} from '@wix/ecom-platform-express-checkout-buttons';
import {NavigationService} from '../services/NavigationService';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {SPECS} from '../../components/Checkout/constants';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {CheckoutService} from '../services/CheckoutService';
import {BIService} from '../services/BIService';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ExpressCheckoutStore {
  constructor({
    controllerConfig,
    navigationService,
    checkoutService,
    siteStore,
    flowAPI,
    biService,
  }: {
    controllerConfig: IWidgetControllerConfig;
    navigationService: NavigationService;
    checkoutService: CheckoutService;
    siteStore: SiteStore;
    flowAPI: ControllerFlowAPI;
    biService: BIService;
  }) {
    if (
      !(
        siteStore.experiments.enabled(SPECS.UseNewCheckoutInVisitorPickup) ||
        siteStore.experiments.enabled(SPECS.UseNewCheckoutInRestOfVisitorFlows) ||
        siteStore.experiments.enabled(SPECS.UseNewCheckoutInRestOfTheFlows)
      )
    ) {
      return;
    }

    const controller = createExpressCheckoutButtonsController({
      currency: navigationService.currency ?? siteStore.currency,
      getCheckout: /* istanbul ignore next */ () => Promise.resolve(checkoutService.ambassadorCheckout),
      controllerConfig,
      httpClient: flowAPI.essentials.httpClient,
      continueShoppingUrl: navigationService.externalContinueShoppingUrl,
      onCancel: () => navigationService.reload(),
      onClick: () => biService.clickOnCheckoutWithEWallet(checkoutService.checkout),
    });

    void controller.init();
  }
}

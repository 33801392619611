import {ListResponse} from '@wix/ambassador-addresses-api-v1-address/types';
import {MemberAddressModel} from './MemberAddress.model';

export class MemberAddressesInfoModel {
  public addresses?: MemberAddressModel[];
  public defaultAddressId?: string;

  constructor({addresses, defaultAddressId}: ListResponse) {
    this.addresses = (addresses ?? []).map((address) => new MemberAddressModel(address));
    this.defaultAddressId = defaultAddressId ?? '';
  }
}

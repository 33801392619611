import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutService} from '../services/CheckoutService';
import {NavigationService} from '../services/NavigationService';
import {createSlotVeloAPIFactory} from '@wix/widget-plugins-ooi/velo';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {SlotId} from '../utils/slotId';
import {StepsManagerService} from '../services/StepsManagerService';
import {StepId} from '../../types/app.types';

type CheckoutSlotApi = {
  isMethodSupported: (methodName: string) => boolean;
  checkoutId: string;
  stepId: StepId | null;
  onRefreshCheckout: (callback: () => Promise<void>) => void;
  slotId: SlotId;
};

export class SlotsStore {
  private readonly slotAPIFactory!: ReturnType<typeof createSlotVeloAPIFactory>;
  private readonly siteStore: SiteStore;
  private readonly checkoutService: CheckoutService;
  private readonly navigationService: NavigationService;
  private readonly stepsManagerService: StepsManagerService;
  private readonly updateComponent: () => void;

  constructor({
    controllerConfig,
    siteStore,
    checkoutService,
    updateComponent,
    navigationService,
    stepsManagerService,
  }: {
    controllerConfig: IWidgetControllerConfig;
    siteStore: SiteStore;
    checkoutService: CheckoutService;
    updateComponent: () => void;
    navigationService: NavigationService;
    stepsManagerService: StepsManagerService;
  }) {
    this.siteStore = siteStore;
    this.checkoutService = checkoutService;
    this.updateComponent = updateComponent;
    this.navigationService = navigationService;
    this.stepsManagerService = stepsManagerService;
    this.slotAPIFactory = createSlotVeloAPIFactory(controllerConfig);
  }

  /* istanbul ignore next: test slot */
  private readonly setSlotsParams = (slotId: SlotId): void => {
    const slotApi = this.slotAPIFactory.getSlotAPI(slotId) as CheckoutSlotApi;
    slotApi.checkoutId = this.navigationService.checkoutId!;
    slotApi.slotId = slotId;
    slotApi.stepId = this.navigationService.isFastFlow
      ? StepId.placeOrder
      : this.stepsManagerService.getActiveStep().stepId;
    slotApi.onRefreshCheckout(async () => {
      await this.checkoutService.fetchCheckout();
      this.updateComponent();
    });
  };

  /* istanbul ignore next: test slot */
  private readonly setStepId = (slotId: SlotId, stepId: StepId | null): void => {
    const slotApi = this.slotAPIFactory.getSlotAPI(slotId) as CheckoutSlotApi;
    slotApi.stepId = stepId || StepId.placeOrder;
  };

  public toProps = () => {
    return {
      setSlotsParams: this.setSlotsParams,
      setStepId: this.setStepId,
    };
  };
}

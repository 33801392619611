import {ActiveStep, StepId} from '../../types/app.types';
import {CheckoutModel} from '../models/Checkout.model';
import {SPECS} from '../../components/Checkout/constants';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';

export class StepsManagerService {
  private readonly siteStore: SiteStore;
  private readonly visitedSteps: StepId[] = [];
  private previousStepId?: StepId;
  private steps: StepId[] = [];
  private readonly activeStep: ActiveStep = {stepId: StepId.customerDetails, stepIndex: 0};

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.siteStore = siteStore;
  }

  public readonly goToNextStep = (): void => {
    const nextStepIndex = this.activeStep.stepIndex + 1;
    this.updateStepOnStage(nextStepIndex, this.steps[nextStepIndex]);
  };

  public readonly getSteps = (checkout: CheckoutModel): StepId[] => {
    return [
      StepId.customerDetails,
      ...(this.siteStore.experiments.enabled(SPECS.UseNewCheckoutInRestOfVisitorFlows) && checkout.hasShippableItems
        ? [StepId.deliveryMethod]
        : []),
      ...(checkout.payNowTotalAfterGiftCard.amount ? [StepId.payment] : []),
      StepId.placeOrder,
    ];
  };

  public get stepsList(): StepId[] {
    return this.steps;
  }

  public set stepsList(steps: StepId[]) {
    this.steps = steps;
  }

  public readonly updateStepOnStage = (stepIndex: number, stepId: StepId): void => {
    this.previousStepId = this.activeStep.stepId ?? /* istanbul ignore next */ undefined;
    this.activeStep.stepId = stepId;
    this.activeStep.stepIndex = stepIndex;
    !this.visitedSteps.includes(stepId) && this.visitedSteps.push(stepId);
  };

  public readonly isStepInQueue = (stepId: StepId): boolean => {
    return this.visitedSteps.includes(stepId);
  };

  public readonly getPreviousStep = (): StepId | undefined => {
    return this.previousStepId;
  };

  public readonly getActiveStep = (): ActiveStep => {
    return this.activeStep;
  };
}
